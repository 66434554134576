.hidden {
  display: unset;

  &-xs {
    @media (max-width: 575px) {
      display: none;
    }
  }

  &-sm {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &-md {
    @media (max-width: 991px) {
      display: none;
    }
  }

  &-lg {
    @media (max-width: 1199px) {
      display: none;
    }
  }
}